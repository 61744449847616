.v133_373 {
  /* width: 219px; */
  color: rgba(32, 33, 36, 1);
  font-family: Lexend Deca;
  font-weight: Bold;
  font-size: 48px;
  opacity: 1;
  text-align: left;
}
.v133_381 {
  color: rgba(32, 33, 36, 1);
  font-family: Lexend Deca;
  font-weight: Medium;
  font-size: 32px;
  margin-top: 10px;
  opacity: 1;
  text-align: left;
}
.v133_382 {
  /* width: 681px; */
  color: rgba(107, 107, 107, 1);
  font-family: Lexend Deca;
  font-weight: Light;
  font-size: 18px;
  opacity: 1;
  text-align: left;
  margin-top: 30px;
}
.v133_383 {
  width: 130px;
  color: rgba(53, 54, 58, 1);
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 20px;
  opacity: 1;
  text-align: left;
  margin-top: 60px;
}

.single-blog-wrapper {
  display: flex;
}
