.frame-2-custom.group-26 {
  max-width: 1408px;
}

.frame-2-custom .frame-wrapper {
  background-color: #fdfcfc;
  border-radius: 24px;
  height: 100%;
}

.frame-2-custom .frame-6 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 32px 56px;
  left: 56px;
  position: relative;
  top: 82px;
  /* width: 787px; */
}

.frame-2-custom .group-27 {
  /* height: 140px; */
  display: flex;
  align-items: center;
  gap: 16px;
}

.frame-2-custom .rectangle-17 {
  border: 2px solid;
  border-color: #e0e0e0;
  border-radius: 16px;
  height: 140px;
  width: 140px;
  /* background-color: black; */
}

.frame-2-custom .group-28 {
  /* max-width: 627px; */
  width: 98%;
}

.frame-2-custom .text-wrapper-14 {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.72px;
  line-height: normal;
  width: 80%;
}

.frame-2-custom .text-wrapper-15 {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.72px;
  line-height: normal;
  width: 80%;
}

.frame-2-custom .group-29 {
  height: 124px;
  width: 627px;
}

.frame-2-custom .text-wrapper-16 {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.72px;
  line-height: normal;
  width: 623px;
}

.frame-2-custom .text-wrapper-17 {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.72px;
  line-height: normal;
  width: 558px;
}

.frame-2-custom .group-30 {
  height: 94px;
  width: 623px;
}

.frame-2-custom .group-31 {
  height: 94px;
  position: relative;
  width: 627px;
}

/* 







nenene 















*/

/* nenene 









*/

/* nenene 







*/

.frame-2-custom .group-32 {
  background-color: #263d49;
  border-radius: 24px;
  height: 892px;
  width: 100%;
  padding-right: 34px;
  padding-left: 34px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.frame-2-custom .rectangle-18 {
  background-color: #e0e0e0;
  border-radius: 8px;
  height: 1px;
  opacity: 0.1;
  /* width: 429px; */
  margin-bottom: 30px;
}

.frame-2-custom .group-33 {
  margin-bottom: 20px;
}

.frame-2-custom .rectangle-19 {
  background-color: #75ce4c;
  border-radius: 24px;
  height: 136px;

  transform: rotate(180deg);
  width: 429px;
}

.frame-2-custom .group-34 {
  height: 54px;
  width: 322px;
}

.frame-2-custom .text-wrapper-18 {
  color: #263c49;
  font-family: 'Overpass', Helvetica;
  font-size: 48px;
  font-weight: 600;
  height: 52px;
  left: 0;
  letter-spacing: -2.4px;
  line-height: normal;

  top: 2px;
  white-space: nowrap;
  cursor: pointer;
  /* width: 256px; */
}

.frame-2-custom .group-35 {
  height: 48px;
  left: 272px;

  top: 0;
  width: 48px;
}

.frame-2-custom .rectangle-20 {
  background-color: #263d49;
  border-radius: 12px;
  height: 48px;
  left: 0;

  top: 0;
  transform: rotate(180deg);
  width: 48px;
}

.frame-2-custom .arrow-arrow-right-md-2 {
  height: 40px !important;
  left: 4px !important;
  position: absolute !important;
  top: 4px !important;
  width: 40px !important;
}

.frame-2-custom .group-36 {
}

.frame-2-custom .group-37 {
  height: 425px;
  position: relative;
  /* width: 433px; */
}

.frame-2-custom .text-wrapper-19 {
  color: #fdfcfc;
  font-family: 'Overpass', Helvetica;
  font-size: 80px;
  font-weight: 700;
  left: 0;
  letter-spacing: -2.4px;
  line-height: 81px;
  margin-top: 24px;
}

.frame-2-custom .group-38 {
  height: 64px;
  left: 0;

  top: 0;
  width: 64px;
}

.frame-2-custom .overlap-group-5 {
  border-radius: 16px;
  height: 64px;
  position: relative;
}

.frame-2-custom .rectangle-21 {
  background-color: #75ce4c;
  border-radius: 16px;
  height: 64px;
  left: 0;

  top: 0;
  transform: rotate(180deg);
  width: 64px;
}

.frame-2-custom .communication-chat {
  height: 40px !important;
  left: 12px !important;
  position: absolute !important;
  top: 12px !important;
  width: 40px !important;
}

.frame-2-custom .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 240px;
  width: 96%;
  margin-top: 20px;
}

.frame-2-custom p.text {
  align-self: stretch;
  color: #fdfcfc;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.72px;
  line-height: 32px;
  position: relative;
}

.frame-2-custom span.span {
  color: #fdfcfc;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.72px;
  line-height: 32px;
}

.custom-flex {
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: #75ce4c;
  justify-content: center;
  border-radius: 24px;
  height: 136px;
}

.overlap-group-4 {
  position: relative;
}

.left-icon-wrapper {
  position: relative;
}
.left-icon {
  position: absolute;
  top: 21%;
  left: 25%;
}

@media only screen and (max-width: 600px) {
  .frame-2-custom.group-26 {
    width: 94%;
  }

  .frame-2-custom .frame-wrapper {
    padding-top: 15px;
    padding-left: 10px;
    padding-bottom: 30px;
    border-radius: 15px;
  }

  .frame-2-custom .group-27 {
    /* height: 140px; */
    display: flex;
    align-items: baseline;
    flex-direction: column;
  }
  .frame-2-custom .frame-6 {
    gap: 12px;
    position: initial;
  }

  .frame-2-custom .rectangle-17 {
    border: 2px solid;
    border-color: #e0e0e0;
    border-radius: 16px;
    height: 80px;
    width: 80px;
    background-color: initial;
  }
  .frame-2-custom .text-wrapper-14 {
    font-size: 16px;
    margin-top: 6px;
    letter-spacing: 0.7px;
    width: 98%;
  }

  .frame-2-custom .text-wrapper-15 {
    font-size: 16px;
    width: 98%;
  }
  .frame-2-custom .group-32 {
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
  }
  .frame-2-custom .frame-wrapper,
  .frame-2-custom .group-32 {
    width: 98%;
  }
  .custom-flex {
    border-radius: 12px;
    height: 100px;
    width: 90%;
  }
  .frame-2-custom .flexcontainer {
    gap: 0px;
    height: fit-content;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .frame-2-custom .group-37 {
    height: max-content;
  }
  .frame-2-custom .text-wrapper-18 {
    font-size: 32px;
    height: initial;
    cursor: pointer;
  }
  .frame-2-custom .group-33 {
    display: flex;
    justify-content: center;
    gap: 0px;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .frame-2-custom .rectangle-20 {
    height: 32px;
    transform: rotate(180deg);
    width: 32px;
    border-radius: 8px;
  }
  .frame-2-custom .arrow-arrow-right-md-2 {
    height: 25px !important;
    left: 4px !important;
    position: absolute !important;
    top: 4px !important;
    width: 25px !important;
  }
  .frame-2-custom .rectangle-18 {
    width: 90%;
  }
  .left-icon {
    /* position: absolute;
  top: 21%;
  left: 20%; */
    height: 40px;
    width: 40px;
  }
}
