* {
  box-sizing: border-box;
}
body {
  font-size: 14px;
}
.v133_104 {
  width: 100%;
  height: 3524px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: relative;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_105 {
  width: 100%;
  height: 70px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: relative;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_106 {
  width: 100%;
  height: 3454px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 70px;
  left: 0px;
  overflow: hidden;
}
.v133_107 {
  width: 100%;
  height: 114px;
  background: rgba(255, 255, 255, 1);
  padding: 32px 140px;
  margin: 739px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 2px;
  overflow: hidden;
}
.v133_108 {
  width: 100px;
  height: 29px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 42px;
  left: 140px;
  overflow: hidden;
}
.v133_109 {
  width: 100px;
  height: 29px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: relative;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_110 {
  width: 572px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 48px;
  opacity: 1;
  position: absolute;
  top: 32px;
  left: 728px;
  overflow: hidden;
}
.v133_111 {
  width: 98px;
  color: rgba(32, 33, 36, 1);
  position: absolute;
  top: 16px;
  left: 0px;
  font-family: Lexend;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v133_112 {
  width: 42px;
  color: rgba(32, 33, 36, 1);
  position: absolute;
  top: 16px;
  left: 146px;
  font-family: Lexend;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v162_865 {
  width: 38px;
  color: rgba(32, 33, 36, 1);
  position: absolute;
  top: 16px;
  left: 236px;
  font-family: Lexend;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v133_113 {
  width: 54px;
  color: rgba(32, 33, 36, 1);
  position: absolute;
  top: 16px;
  left: 322px;
  font-family: Lexend;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v133_114 {
  width: 148px;
  height: 50px;
  background: rgba(0, 167, 255, 1);
  padding: 16px 24px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 424px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}
.v133_115 {
  width: 100px;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 16px;
  left: 24px;
  font-family: Lexend;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
