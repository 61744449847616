.arrowArrow_Right_MD,
.arrowArrow_Right_MD path {
  stroke: white;
}
.arrowArrow_Right_MD {
  width: 48px;
  height: 48px;
}
.footer-new.root {
  background-image: url('../../icons/Group\ 39906.png');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  width: 100%;
  max-width: 1408px;
  /* height: 780px, */
}
.footer-new .group39819 {
  position: relative;
  padding-left: 170px;
  padding-top: 190px;
  padding-bottom: 50px;
}
.footer-new .group39906 {
  /* width: 1408px; */
  height: 1408px;
  overflow: visible;
}
.footer-new .icon {
  width: 100%;
  height: 100%;
}
.footer-new .AnvaiInc,
.footer-new .privacy,
.footer-new .terms {
  opacity: 0.5;
  color: #fdfcfc;
  font-size: 24px;
  font-weight: 600;
  font-family: Overpass, system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  letter-spacing: -0.05em;
}
.footer-new .frame39888 {
  gap: 16px;
  display: flex;
}
/* .footer-new .privacy {
  opacity: 0.5;
  color: #fdfcfc;
  font-size: 24px;
  font-weight: 600;
  font-family: Overpass, system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  letter-spacing: -0.05em;
}
.footer-new .terms {
  opacity: 0.5;
  color: #fdfcfc;
  font-size: 24px;
  font-weight: 600;
  font-family: Overpass, system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  letter-spacing: -0.05em;
} */
.footer-new .frame39763 {
  gap: 24px;
  display: flex;
}
.footer-new .product,
.footer-new .useCases,
.footer-new .aboutUs {
  color: #fdfcfc;
  font-size: 24px;
  font-weight: 600;
  font-family: Overpass, system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  letter-spacing: -0.05em;
}
/* .footer-new .useCases {
  color: #fdfcfc;
  font-size: 24px;
  font-weight: 600;
  font-family: Overpass, system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  letter-spacing: -0.05em;
}
.footer-new .aboutUs {
  color: #fdfcfc;
  font-size: 24px;
  font-weight: 600;
  font-family: Overpass, system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  letter-spacing: -0.05em;
} */
.footer-new .frame39764 {
  gap: 24px;
  display: flex;
}
.footer-new .linkedIn,
.footer-new .twitter {
  color: #fdfcfc;
  font-size: 24px;
  font-weight: 600;
  font-family: Overpass, system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  letter-spacing: -0.05em;
}
/* .footer-new .twitter {
  color: #fdfcfc;
  font-size: 24px;
  font-weight: 600;
  font-family: Overpass, system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  letter-spacing: -0.05em;
} */
.footer-new .earlyAccess {
  color: #263d49;
  font-size: 48px;
  font-weight: 600;
  font-family: Overpass, system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  letter-spacing: -0.05em;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.footer-new .rectangle725 {
  transform-origin: top left;
  border-radius: 12px;
  background-color: #263d49;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.footer-new .group39801 {
  width: 565px;
  height: 135px;
}
.footer-new .icon2 {
  width: 100%;
  height: 100%;
}
.footer-new .tapUnstructuredDataToMaximizeB {
  color: #fdfcfc;
  font-size: 40px;
  font-weight: 600;
  font-family: Overpass, system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  letter-spacing: -0.05em;
  height: min-content;
  flex-direction: column;
  width: 44%;
  margin-top: 30px;
}

.footer-custom-flex {
  display: flex;
  gap: 80px;
  margin-top: 40px;
  align-items: center;
}
.footer-custom-flex-2 {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.early-access-wrapper {
  display: flex;
  padding: 32px 40px;
  background-color: #75ce4c;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

.arrow-wrapper {
  background-color: #263d49;
  padding: 8px 10px;
  border-radius: 10px;
}

.footer-2-wrapper {
  position: relative;
  left: 40%;
  margin-top: 30px;
  display: flex;
  gap: 54px;
}

@media only screen and (max-width: 600px) {
  .footer-new.root {
    background-image: url('../../icons/Group-39906.png');
    width: 94%;
    height: 450px;
  }
  .footer-custom-flex {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
    margin-top: 30px;
  }
  .footer-custom-flex-2 {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .footer-new .group39819 {
    padding-left: 70px;
    padding-top: 80px;
    padding-bottom: initial;
  }
  .footer-new .group39801 {
    width: 224px;
    height: 54px;
  }
  .footer-new .tapUnstructuredDataToMaximizeB {
    color: #fdfcfc;
    font-size: 16px;
    width: 83%;
    margin-top: 25px;
  }
  .early-access-wrapper {
    padding: 10px 50px;
  }
  .footer-new .earlyAccess {
    font-size: 18px;
  }
  .footer-new .arrow-wrapper {
    padding: initial;
    background-color: initial;
  }
  .arrowArrow_Right_MD {
    width: 24px;
    height: 24px;
  }
  .arrowArrow_Right_MD,
  .arrowArrow_Right_MD path {
    stroke: #0e231c;
  }
  .footer-new .product,
  .footer-new .useCases,
  .footer-new .aboutUs {
    color: #fdfcfc;
    font-size: 12px;
  }

  .footer-new .linkedIn,
  .footer-new .twitter {
    color: #fdfcfc;
    font-size: 12px;
  }

  .footer-2-wrapper {
    position: initial;
    left: 40%;
    margin-top: 30px;
    display: flex;
    gap: 54px;
  }

  .footer-new .AnvaiInc,
  .footer-new .privacy,
  .footer-new .terms {
    color: #fdfcfc;
    font-size: 12px;
  }
  .footer-2-wrapper {
    margin-top: 15px;
  }
}
