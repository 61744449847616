.home-new-page {
  background-color: #0e231c;
  /* height: 4463px; */
  position: relative;
  width: 100vw;
}
.width-controller {
  /* max-width: 1408px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.width-controller-wrapper {
  max-width: 1408px;
}

.home-new-page .frame-5 {
  height: 40px;
  left: 251px;
  position: absolute;
  top: 24px;
  width: 152px;
}

.home-new-page .group-25 {
  height: 2658px;
  left: 249px;
  /* position: absolute; */
  top: 937px;
  width: 1408px;
}

.home-new-page .frame-15 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 180px;
  height: 844px;
  left: 249px;
  position: absolute;
  top: 3619px;
}

.home-new-page .group-54 {
  height: 843px;
  position: relative;
  width: 1408px;
}
