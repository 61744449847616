.v162_853 {
  margin: 12px;
  text-align: center;
}
.v162_854 {
  color: rgba(0, 0, 0, 1);
  font-family: Lexend;
  font-weight: 500;
  font-style: Light;
  font-size: 36px;
  opacity: 1;
  text-align: left;
}
.v162_855 {
  color: rgba(0, 0, 0, 1);
  font-family: Lexend;
  font-weight: 200;
  font-size: 20px;
  opacity: 1;
  text-align: center;
}
.v5_48 {
  margin: 14px;
  overflow: hidden;
  padding-right: 10px;
}
.v5_47 {
  margin: 14px;
  opacity: 1;
  overflow: hidden;
}
.v5_42 {
  opacity: 1;
  overflow: hidden;
}
.v5_42 img {
  width: 19px;
  height: 19px;
}
.v5_44 {
  color: rgba(0, 0, 0, 1);
  font-family: Lexend;
  font-weight: 600;
  font-size: 20px;
  opacity: 1;
  text-align: left;
  margin-left: 10px;
}
.v5_46 {
  color: rgba(0, 0, 0, 1);
  font-family: 'Lexend';
  font-weight: 200;
  font-size: 14px;
  opacity: 1;
  text-align: left;
  line-height: 25px;
}
.position-wrapper {
  margin-top: 40px;
}
.job-display {
  padding: 20px 10px;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  box-shadow: 3px 3px #00000036;
}
.job-display:hover {
  background-color: #00a7ff;
  color: white;
}
.job-display-title {
  font-size: 20px;
  font-weight: 600;
  font-family: Lexend;
}
.job-location {
  display: flex;
  justify-content: center;
  margin-top: 4px;
}
.job-location .icon {
  color: blue;
}
.job-location .location-info {
  font-size: 14px;
  margin-left: 10px;
}
.applyvia-box {
  font-size: 12px;
  margin-top: 4px;
}
@media only screen and (max-width: 700px) {
  .v5_48 {
    margin: 5px;
  }
}
