.title-wrapper {
  display: flex;
  justify-content: center;
}
.title-wrapper .title {
  font-size: 32px;
  font-weight: 500;
  font-family: Lexend;
}

.section {
  padding: auto;
}
.section .section-title {
  text-decoration: underline;
  font-weight: 700;
  font-size: 16px;
}
.section .section-content {
  font-size: 16px;
}
