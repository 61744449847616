* {
  box-sizing: border-box;
}
body {
  font-size: 14px;
}
.v143_349 {
  width: 100%;
  height: 369px;
  position: relative;
  top: 0px;
  left: 0px;
  overflow: hidden;
  margin-top: 60px;
}
.v143_350 {
  width: 100%;
  height: 369px;
  background: rgba(28, 156, 224, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v143_351 {
  width: 335px;
  height: 335px;
  background: rgba(0, 167, 255, 1);
  opacity: 1;
  position: absolute;
  top: 30%;
  left: 5%;
  border-radius: 50%;
}
.v143_352 {
  width: 178px;
  height: 178px;
  background: rgba(0, 167, 255, 1);
  opacity: 1;
  position: absolute;
  top: -4%;
  left: -2%;
  border-radius: 50%;
}
.v143_353 {
  width: 197px;
  height: 520px;
  background: rgba(0, 167, 255, 1);
  opacity: 1;
  position: absolute;
  top: 22%;
  right: 4%;
  border-top-left-radius: 231px;
  border-top-right-radius: 231px;
  border-bottom-left-radius: 231px;
  border-bottom-right-radius: 231px;
  transform: rotate(-42deg);
  overflow: hidden;
}
.v143_354 {
  width: 197px;
  height: 520px;
  background: rgba(0, 167, 255, 1);
  opacity: 1;
  position: absolute;
  bottom: 27%;
  right: 2%;
  border-top-left-radius: 231px;
  border-top-right-radius: 231px;
  border-bottom-left-radius: 231px;
  border-bottom-right-radius: 231px;
  transform: rotate(-42deg);
  overflow: hidden;
}

/* 



Mid content 




*/

.v143_355 {
  width: 100%;
  height: 90%;
  /* margin: 64px; */
  opacity: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}
.v143_356 {
  /* width: 641px; */
  height: 90%;
  margin: 33px;
  opacity: 1;
}
.v143_357 {
  color: rgba(255, 255, 255, 1);
  font-family: Lexend;
  font-weight: SemiBold;
  font-size: 36px;
  opacity: 1;
  text-align: center;
  position: relative;
}
.v143_358 {
  color: rgba(255, 255, 255, 1);
  font-family: Lexend;
  font-weight: Light;
  font-size: 24px;
  opacity: 1;
  text-align: center;
  position: relative;
}

.v143_358_1 {
  color: rgba(255, 255, 255, 1);
  font-family: Lexend;
  font-weight: Light;
  font-size: 24px;
  opacity: 1;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.v143_359_1 {
  height: 52px;
  padding: 16px 24px;
  opacity: 1;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v143_359 {
  width: 165px;
  height: 52px;
  background: rgba(255, 255, 255, 1);
  padding: 16px 24px;
  opacity: 1;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}
.v143_360 {
  width: 117px;
  color: rgba(16, 5, 77, 1);
  font-family: Lexend;
  font-weight: SemiBold;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
