.v138_231 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.v138_232 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.v138_233 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;

  color: #202124;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
/* .v138_232 {
  margin: 24px;
}
.v138_233 {
  color: rgba(32, 33, 36, 1);
  font-family: 'Lexend';
  font-weight: 700;
  text-align: left;
} */
.v138_234 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;

  color: #6b6b6b;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.v138_235 {
  background: rgba(0, 167, 255, 1);
  padding: 16px 24px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: max-content;
  margin-left: 20px;
}
.v138_236 {
  color: rgba(255, 255, 255, 1);
  font-family: Lexend;
  font-weight: SemiBold;
  font-size: 16px;
  text-align: left;
}
/* .react-player-wrapper {
  margin-top: 13px;
}
.react-player-img {
  margin-top: 20px;
} */
@media only screen and (max-width: 700px) {
  .v138_232 {
    margin: 0px;
    margin-top: 10px;
  }
  .v138_235 {
    margin-top: 10px;
    margin-left: 0px;
  }
  .v138_233 {
    font-size: 26px;
  }
  .react-player-wrapper {
    margin-top: 0px;
  }
  .react-player-img {
    margin-top: 0px;
  }
}
