.heading {
  display: flex;
  justify-content: center;
  /* padding-top: 20px; */
  padding-bottom: 20px;
}
.heading_title {
  /* font-weight: 700; */
  font-size: 32px;
  text-decoration: dashed;
  font-family: Lexend Deca;
}

.blogBody .section_heading {
  /* font-weight: 700; */
  font-size: 23px;
  font-family: Lexend Deca;
}

.blogBody .section {
  /* padding-top: 20px; */
  padding-bottom: 20px;
}

.blogBody p {
  line-height: 29px;
  font-family: Lexend Deca;
  color: rgba(107, 107, 107, 1);
  font-size: 18px;
}
