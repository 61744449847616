* {
  box-sizing: border-box;
}
body {
  font-size: 14px;
}
.v132_198 {
  color: rgba(0, 0, 0, 1);
  font-family: 'Lexend';
  font-weight: 100;
  font-size: 36px;
  opacity: 1;
  text-align: center;
}
.v132_199 {
  color: rgba(32, 33, 36, 0.75);
  margin-top: 10px;
  font-family: 'Lexend Deca';
  font-weight: 100;
  font-size: 16px;
  opacity: 1;
  text-align: center;
}
.wrapper-grid {
  margin-top: 49px;
}
@media only screen and (max-width: 700px) {
  .wrapper-grid {
    margin-top: 29px;
  }
}

.v132_148_new {
  display: flex;
  justify-content: center;
  position: relative;
}
.v132_148_new img {
  max-height: 288px;
  min-width: 288px;
  opacity: 1;
  border-radius: 5px;
  align-self: center;
  text-align: center;
  margin-bottom: 10px;
}
.v132_149 {
  margin: 12px;
  opacity: 1;
}
.v132_150 {
  color: rgba(32, 33, 36, 1);
  font-family: Lexend;
  font-weight: 600;
  font-size: 25px;
  opacity: 1;
  text-align: center;
  margin-top: 30px;
  word-wrap: unset;
}
.v132_151 {
  color: rgba(53, 54, 58, 1);
  font-family: Lexend;
  /* font-weight: 600; */
  font-size: 19px;
  opacity: 1;
  text-align: center;
  margin-top: 5px;
  text-transform: uppercase;
  /* text-decoration: underline; */
}
.v132_152 {
  color: rgba(107, 107, 107, 1);
  font-family: Lexend;
  font-weight: Light;
  font-size: 18px;
  opacity: 1;
  text-align: left;
  margin-top: 20px;
  line-height: 29px;
}
.v132_152 b {
  color: black;
  font-weight: 600;
  /* font-style: italic; */
  font-family: Lexend Deca;
}

.linkedIn-icon {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -28px;
}
