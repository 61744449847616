.index .group.header_new {
  /* height: 40px; */
  width: 100%;
  padding-left: 20px;
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
}

.group.header_new .img {
  height: 36px;
  left: 0;
  object-fit: cover;
  padding-top: 2px;
  width: 144px;
  cursor: pointer;
}
.group.header_new .second-half {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.group.header_new .overlap-group-wrapper {
  height: 40px;
  margin-right: 10px;
  margin-left: 30px;
}

.group.header_new .overlap-group {
  border-radius: 24px;
  height: 40px;
  position: relative;
}

.group.header_new .rectangle {
  background-color: #75ce4c;
  border-radius: 24px;
  height: 40px;
  transform: rotate(180deg);
  width: 186px;
}

.group.header_new .div {
  height: 25px;
  left: 16px;
  position: absolute;
  top: 8px;
  width: 156px;
}

.group.header_new .text-wrapper {
  color: #0e231c;
  font-family: 'Overpass', Helvetica;
  font-size: 18px;
  font-weight: 600;
  height: 23px;
  letter-spacing: -0.9px;
  line-height: normal;
  cursor: pointer;
}

.group.header_new .arrow-arrow-right-MD {
  height: 24px !important;
  left: 130px !important;
  position: absolute !important;
  top: 0 !important;
  width: 24px !important;
}

.group.header_new .frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 24px;
}

.group.header_new .text-wrapper-2 {
  color: #fdfcfc;
  font-family: 'Overpass', Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.9px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  cursor: pointer;
}

.mob-second-half,
.dropdown-menu-wrapper {
  display: none;
}
@media only screen and (max-width: 600px) {
  /* HeaderNew Component */
  .index .group.header_new {
    padding-left: 12px;
    padding-top: 12px;
  }
  .group.header_new .img {
    height: 25px;
    object-fit: cover;
    /* padding-top: 2px; */
    width: 100px;
    cursor: pointer;
  }
  .group.header_new .second-half {
    display: none;
  }
  .img-wrapper {
    position: relative;
    right: 17px;
  }

  .dropdown-menu-wrapper {
    display: none;
  }

  .mob-second-half {
    display: initial;
  }
  .dropdown-menu-wrapper.open {
    display: contents;
    /* position: absolute;
    height: 200vh;
    width: 100%;
    opacity: 0.8;
    background-color: #0e231c; */
  }
  .dropdown-menu-wrapper .menu-wrapper {
    width: 100%;
    position: absolute;
    height: 100vh;
    width: 100%;
    opacity: 0.8;
    background-color: #0e231c;
    top: 0px;
    left: 0px;
    z-index: 999;
  }
  .dropdown-menu-wrapper .close-button {
    position: absolute;
    right: 15px;
    top: 10px;
  }
  .dropdown-menu-wrapper .frame-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 31px;
    position: absolute;
    top: 40%;
    left: 30%;
  }
  .dropdown-menu-wrapper .text-wrapper-9 {
    font-size: 32px;
    color: white;
  }
}
