.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 60%;
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  border-radius: 10px;
  border: none;
}

.modal-wrapper .section-heading-wrapper {
  margin-bottom: 20px;
  text-align: center;
  padding-left: 65px;
  padding-right: 65px;
  position: relative;
}

.modal-wrapper .section-heading-title {
  color: #263d49;
  font-family: 'Overpass';
  font-weight: 600;
  font-size: 42px;
  text-transform: initial;
}

.modal-wrapper .section-heading-subtitle {
  color: initial;
  font-family: 'Overpass';
  font-weight: 300;
  font-size: 20px;
  text-transform: initial;
}

.modal-wrapper .form-input-wrapper {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.modal-wrapper .form-input-item {
  flex: 1;
}

.modal-wrapper .modal-button-wrapper {
  margin-top: 10px;
}

.modal-wrapper .close-button {
  position: absolute;
  right: 20px;
  top: 14px;
  cursor: pointer;
}

.modal-wrapper .menu-close-MD {
  fill: black;
  color: black;
}
.modal-wrapper .menu-close-MD path {
  fill: black;
  stroke: black;
}

@media only screen and (max-width: 600px) {
  .modal-wrapper {
    padding: 10px;
    width: 80%;
  }
  .modal-wrapper .section-heading-wrapper {
    margin-bottom: 5px;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
  }

  .modal-wrapper .section-heading-title {
    font-size: 20px;
  }

  .modal-wrapper .section-heading-subtitle {
    font-size: 11px;
    margin-top: 5px;
  }
  .modal-wrapper .close-button {
    position: absolute;
    right: 9px;
    top: 5px;
    cursor: pointer;
  }
  .modal-wrapper .modal-body-wrapper,
  .modal-wrapper .modal-button-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
  .modal-wrapper .form-input-wrapper {
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
}
