.frame-1-custom.overlap {
  background-color: #fdfcfc;
  border-radius: 24px;
  max-width: 1408px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.frame-1-custom .second-graph-wrapper {
  position: relative;
}

.frame-1-custom .overlap-4 {
  border-radius: 8px;
  position: relative;
  right: -50px;
  top: -1px;
}

.frame-1-custom .rectangle-2 {
  background-color: #fdfcfc;
  border: 1px solid;
  border-color: #e0e0e0;
  border-radius: 8px;
  height: 74px;
  left: 0;
  /* position: absolute; */
  top: 0;
  transform: rotate(180deg);
  width: 200px;
}

.frame-1-custom .vector {
  height: 11px;
  left: 36px;
  position: absolute;
  top: 34px;
  width: 132px;
}

.frame-1-custom .img-custom-class {
  height: 11px;
  left: 35px;
  position: absolute;
  top: 52px;
  width: 132px;
}

.frame-1-custom .ellipse {
  background-color: #75ce4c;
  border-radius: 1.5px;
  height: 3px;
  left: 52px;
  position: absolute;
  top: 58px;
  width: 3px;
}

.frame-1-custom .ellipse-2 {
  background-color: #ea4d59;
  border-radius: 1.5px;
  height: 3px;
  left: 90px;
  position: absolute;
  top: 40px;
  width: 3px;
}

.frame-1-custom .ellipse-3 {
  background-color: #75ce4c;
  border-radius: 1.5px;
  height: 3px;
  left: 77px;
  position: absolute;
  top: 38px;
  width: 3px;
}

.frame-1-custom .ellipse-4 {
  background-color: #75ce4c;
  border-radius: 1.5px;
  height: 3px;
  left: 136px;
  position: absolute;
  top: 55px;
  width: 3px;
}

.frame-1-custom .div-wrapper {
  height: 24px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 24px;
}

.frame-1-custom .overlap-group-2 {
  border-radius: 4px;
  height: 24px;
  position: relative;
}

.frame-1-custom .rectangle-3 {
  background-color: #ea4d59;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 24px;
}

.frame-1-custom .icon-instance-node {
  height: 20px !important;
  left: 2px !important;
  position: absolute !important;
  top: 2px !important;
  width: 20px !important;
}

.frame-1-custom .text-wrapper-3 {
  color: #ea4d59;
  font-family: 'Overpass', Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 39px;
  letter-spacing: -0.36px;
  line-height: normal;
  position: absolute;
  top: 13px;
}

.frame-1-custom .overlap-5 {
  border-radius: 8px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px;
  border: 2px solid #e0e0e0;
  width: fit-content;
  padding-right: 35px;
}

.frame-1-custom .rectangle-4 {
  background-color: #fdfcfc;
  border: 1px solid;
  border-color: #e0e0e0;
  border-radius: 8px;
  height: 32px;
  left: 0;
  /* position: absolute; */
  top: 0;
  transform: rotate(180deg);
  width: 147px;
}

.frame-1-custom .icon-instance-node-2 {
  height: 24px;
  width: 24px;
}

.frame-1-custom .custom-icon-instance-node-2 {
  height: 24px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 24px;
}

.frame-1-custom .overlap-group-3 {
  border-radius: 6px;
  height: 24px;
  position: relative;
}

.frame-1-custom .rectangle-5 {
  background-color: #f99a1c;
  border-radius: 6px;
  height: 24px;
  left: 0;
  /* position: absolute; */
  top: 0;
  transform: rotate(180deg);
  width: 24px;
}

.frame-1-custom .text-wrapper-4 {
  color: #f99a1c;
  font-family: 'Overpass', Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 36px;
  letter-spacing: -0.36px;
  line-height: normal;
  /* position: absolute; */
  top: 9px;
}

.frame-1-custom .group-3 {
  height: 48px;
  left: 180px;
  position: absolute;
  top: -230px;
  width: 48px;
}

.frame-1-custom .overlap-group-4 {
  border-radius: 12px;
  height: 48px;
  position: relative;
}

.frame-1-custom .rectangle-6 {
  background-color: #0e231c;
  border-radius: 12px;
  height: 48px;
  left: 0;
  /* position: absolute; */
  top: 0;
  transform: rotate(180deg);
  width: 48px;
}

.frame-1-custom .icon-instance-node-3 {
  height: 24px !important;
  left: 12px !important;
  position: absolute !important;
  top: 12px !important;
  width: 24px !important;
}

.frame-1-custom .group-4 {
  height: 32px;
  left: 170px;
  position: absolute;
  top: -100px;
  width: 32px;
}

.frame-1-custom .overlap-6 {
  border-radius: 8px;
  height: 32px;
  position: relative;
}

.frame-1-custom .rectangle-7 {
  background-color: #75ce4c;
  border-radius: 8px;
  height: 32px;
  left: 0;
  /* position: absolute; */
  top: 0;
  transform: rotate(180deg);
  width: 32px;
}

.frame-1-custom .overlap-7 {
  /* height: 464px; */
  left: 6px;
  /* position: absolute; */
  top: 0;
  /* width: 1226px; */
}

.frame-1-custom .group-5 {
  height: 147px;
  left: 990px;
  /* position: absolute; */
  top: 0;
  width: 236px;
}

.frame-1-custom .overlap-8 {
  /* background-image: url(https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536837a596e4ed4927eb7/img/rectangle-767.svg); */
  background-size: 100% 100%;
  height: 147px;
  position: relative;
  width: 234px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding: 10px 20px;
  border: 2px solid #e0e0e0;
  border-radius: 20px;
}

.frame-1-custom .credit-risk {
  color: #efeeee;
  font-family: 'Overpass', Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 16px;
  letter-spacing: -0.36px;
  line-height: normal;
  /* position: absolute; */
  top: 16px;
}

.frame-1-custom .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  left: 16px;
  /* position: absolute; */
  top: 47px;
}

.frame-1-custom .text-wrapper-5 {
  color: #cbc7c7;
  font-family: 'Overpass', Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.36px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.frame-1-custom .text-wrapper-6 {
  color: #cbc7c7;
  font-family: 'Overpass', Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.36px;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.frame-1-custom .rectangle-wrapper {
  background-image: url(https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536837a596e4ed4927eb7/img/rectangle-772.svg);
  background-size: 100% 100%;
  height: 10px;
  left: 55px;
  /* position: absolute; */
  top: 48px;
  width: 159px;
}

.frame-1-custom .img-wrapper {
  background-image: url(https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536837a596e4ed4927eb7/img/rectangle-773.svg);
  background-size: 100% 100%;
  height: 10px;
  width: 159px;
}

.frame-1-custom .rectangle-9 {
  height: 10px;
  width: 45px;
}

.frame-1-custom .overlap-9 {
  background-image: url(https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536837a596e4ed4927eb7/img/rectangle-774.svg);
  background-size: 100% 100%;
  height: 10px;
  width: 159px;
}

.frame-1-custom .overlap-10 {
  background-image: url(https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536837a596e4ed4927eb7/img/rectangle-772.svg);
  background-size: 100% 100%;
  height: 10px;
  width: 159px;
}

.frame-1-custom .group-6 {
  /* margin-top: 20px; */
}
.right-side-custom {
  display: flex;
  flex-direction: column;
  gap: 22px;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
  position: relative;
}

.frame-1-custom .overlap-11 {
  /* background-image: url(https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536837a596e4ed4927eb7/img/rectangle-767-1.svg); */
  background-size: 100% 100%;
  height: 125px;
  position: relative;
  width: max-content;
  padding: 10px 20px;
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.frame-1-custom .ESG-score-PEER {
  color: #e0e0e0;
  font-family: 'Overpass', Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 16px;
  letter-spacing: -0.36px;
  line-height: normal;
  /* position: absolute; */
  top: 16px;
}

.frame-1-custom .frame-3 {
  height: 66px;
  width: 202px;
}

.frame-1-custom .vector-2 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 246px;
  width: 222px;
}

.frame-1-custom .group-wrapper {
}

.frame-1-custom .group-7 {
  display: flex;
  justify-content: center;
}

.frame-1-custom .group-8 {
  position: relative;
}

.frame-1-custom .p {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.72px;
  line-height: normal;
  text-align: center;
  /* width: 393px; */
  padding-left: 70px;
  padding-right: 70px;
  margin-top: 27px;
  margin-bottom: 27px;
}

.frame-1-custom .group-9 {
  /* height: 40px;
  width: 186px; */
}

.frame-1-custom .text-wrapper-7 {
  color: #263c49;
  font-family: 'Overpass', Helvetica;
  font-size: 80px;
  font-weight: 700;
  letter-spacing: -2.4px;
  line-height: 81px;
  text-align: center;
  /* width: 696px; */
}

.frame-1-custom .frame-4 {
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: center;
  margin-bottom: 10px;
}

.frame-1-custom .text-wrapper-8 {
  color: #cbc7c7;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.72px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame-1-custom .arrow-chevron-right {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.frame-1-custom .vector-3 {
  height: 179px;
  left: 91px;
  position: absolute;
  top: 246px;
  width: 52px;
}

.frame-1-custom .vector-4 {
  height: 84px;
  left: 29px;
  position: absolute;
  top: 246px;
  width: 104px;
}

.frame-1-custom .vector-5 {
  height: 53px;
  left: 70px;
  position: absolute;
  top: 194px;
  width: 67px;
}

.frame-1-custom .vector-6 {
  height: 173px;
  left: 71px;
  position: absolute;
  top: 74px;
  width: 70px;
}

.frame-1-custom .group-10 {
  height: 332px;
  left: -1vw;
  position: absolute;
  /* top: 80px; */
  width: 98px;
}

.frame-1-custom .group-11 {
  height: 48px;
  left: 170px;
  position: absolute;
  top: 170px;
  width: 48px;
}

.frame-1-custom .rectangle-12 {
  background-color: #5da4ef;
  border-radius: 12px;
  height: 48px;
  left: 0;
  /* position: absolute; */
  top: 0;
  transform: rotate(180deg);
  width: 48px;
}

.frame-1-custom .group-12 {
  height: 32px;
  left: 170px;
  position: absolute;
  top: 80px;
  width: 32px;
}

.frame-1-custom .rectangle-13 {
  background-color: #ea4d59;
  border-radius: 8px;
  height: 32px;
  left: 0;
  /* position: absolute; */
  top: 0;
  transform: rotate(180deg);
  width: 32px;
}

.frame-1-custom .group-13 {
  height: 48px;
  left: 70px;
  position: absolute;
  top: -20px;
  width: 48px;
}

.frame-1-custom .rectangle-14 {
  background-color: #f99a1c;
  /* border: 2px solid; */
  border-radius: 12px;
  height: 48px;
  left: 0;
  /* position: absolute; */
  top: 0;
  transform: rotate(180deg);
  width: 48px;
}

.frame-1-custom .group-14 {
  height: 136px;
  left: 946px;
  /* position: absolute; */
  top: 673px;
  width: 438px;
}

.frame-1-custom .overlap-12 {
  border-radius: 24px;
  height: 136px;
  position: relative;
}

.frame-1-custom .group-15 {
  height: 86px;
  left: 15px;
  /* position: absolute; */
  top: 30px;
  width: 412px;
}

.frame-1-custom .text-wrapper-9 {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 60px;
  letter-spacing: -0.72px;
  line-height: normal;
  /* position: absolute; */
  top: 2px;
  width: 348px;
}

.frame-1-custom .text-wrapper-10 {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 60px;
  letter-spacing: -0.6px;
  line-height: normal;
  opacity: 0.5;
  /* position: absolute; */
  top: 36px;
  width: 311px;
}

.frame-1-custom .icon-instance-node-4 {
  height: 32px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 32px;
}

.frame-1-custom .rectangle-15 {
  border: 2px solid;
  border-color: #e0e0e0;
  border-radius: 24px;
  height: 136px;
  left: 0;
  /* position: absolute; */
  top: 0;
  transform: rotate(180deg);
  width: 438px;
}

.frame-1-custom .group-17 {
  height: 136px;
  left: 24px;
  /* position: absolute; */
  top: 673px;
  width: 438px;
}

.frame-1-custom .group-18 {
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 35px 35px;
  padding-left: 35px;
  padding-right: 5px;
  gap: 20px;
  border: 2px solid;
  border-color: #e0e0e0;
  border-radius: 24px;
}

.frame-1-custom .group-16 {
  position: relative;
  top: -7px;
}
.frame-1-custom .group-19 {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.frame-1-custom .text-wrapper-11 {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.72px;
  line-height: normal;
  /* position: absolute; */
}

.frame-1-custom .text-wrapper-12 {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.6px;
  line-height: normal;
  opacity: 0.5;
  /* position: absolute; */
  /* width: 310px; */
}

.frame-1-custom .rectangle-16 {
  background-color: #ea4d59;
  border-radius: 12px;
  height: 48px;
  left: 0;
  /* position: absolute; */
  top: 0;
  transform: rotate(180deg);
  width: 48px;
}

.frame-1-custom .group-20 {
  height: 136px;
  left: 485px;
  /* position: absolute; */
  top: 673px;
  width: 438px;
}

.frame-1-custom .group-21 {
  height: 136px;
}

.frame-1-custom .group-22 {
  height: 136px;
  width: 438px;
}

.frame-1-custom .group-23 {
  height: 90px;
  left: 27px;
  /* position: absolute; */
  top: 26px;
  width: 385px;
}

.frame-1-custom .group-24 {
  height: 84px;
  left: 60px;
  /* position: absolute; */
  top: 6px;
  width: 329px;
}

.frame-1-custom .text-wrapper-13 {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 1px;
  letter-spacing: -0.6px;
  line-height: normal;
  opacity: 0.5;
  /* position: absolute; */
  top: 34px;
  width: 324px;
}

.frame-1-custom .overlap-group-wrapper {
  height: 40px;
  margin-right: 10px;
  margin-left: 30px;
  display: flex;
  justify-content: center;
}

.frame-1-custom .overlap-group {
  border-radius: 24px;
  height: 40px;
  position: relative;
}

.frame-1-custom .rectangle {
  background-color: #75ce4c;
  border-radius: 24px;
  height: 40px;
  transform: rotate(180deg);
  width: 186px;
}

.frame-1-custom .div {
  height: 25px;
  left: 16px;
  position: absolute;
  top: 8px;
  width: 156px;
}

.frame-1-custom .text-wrapper {
  color: #0e231c;
  font-family: 'Overpass', Helvetica;
  font-size: 18px;
  font-weight: 600;
  height: 23px;
  letter-spacing: -0.9px;
  line-height: 26px;
  cursor: pointer;
}

.frame-1-custom .arrow-arrow-right-MD {
  height: 24px !important;
  left: 130px !important;
  position: absolute !important;
  top: 0 !important;
  width: 24px !important;
}

.frame-1-custom .custom-frame-2 {
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  display: flex;
  flex-direction: row;
}

.frame-1-custom .custom-text-wrapper-5 {
  color: #cbc7c7;
  font-family: 'Overpass', Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.36px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.frame-1-custom .custom-rectangle-wrapper {
  background-image: url(https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536837a596e4ed4927eb7/img/rectangle-772.svg);
  background-size: 100% 100%;
  height: 10px;
  left: 55px;
  /* position: absolute; */
  top: 48px;
  width: 159px;
}

.frame-1-custom .custom-rectangle-8 {
  height: 10px;
  width: 66px;
}
.frame-1-custom .custom-rectangle-9 {
  height: 10px;
  width: 45px;
}

.frame-1-custom .custom-rectangle-10 {
  height: 10px;
  width: 139px;
}

.frame-1-custom .custom-rectangle-11 {
  height: 10px;
  width: 124px;
}

@media only screen and (max-width: 600px) {
  .frame-1-custom.overlap {
    padding-bottom: 10px;
    width: 92%;
    border-radius: 14px;
    padding-top: 110px;
  }
  .frame-1-custom .text-wrapper-7 {
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -2.4px;
    line-height: 48px;
    /* width: 696px; */
  }
  .frame-1-custom .text-wrapper-8 {
    font-size: 17px;
  }

  .frame-1-custom .p {
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 17px;
    margin-bottom: 17px;
  }

  .frame-1-custom .group-18 {
    align-items: center;
    padding: 25px 17px;
  }
  .frame-1-custom .group-18 {
    flex-direction: column;
    align-items: start;
    width: 97%;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 5px;
  }

  .frame-1-custom .text-wrapper-11 {
    font-size: 16px;
  }

  .frame-1-custom .text-wrapper-12 {
    font-size: 14px;
  }

  .frame-1-custom .rectangle-16,
  .frame-1-custom .rectangle-12,
  .frame-1-custom .rectangle-14 {
    height: 40px;
    transform: rotate(180deg);
    width: 40px;
  }
  .frame-1-custom .icon-instance-node-4 {
    height: 25px;
    left: 8px;
    position: absolute;
    top: 8px;
    width: 25px;
  }

  .frame-1-custom .group-16 {
    position: initial;
    top: 0px;
  }
  .frame-1-custom .group-19 {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
}
