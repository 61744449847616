.contact-grid-container {
  box-shadow: 1px 1px 10px;
  border-radius: 10px;
}
.contact-box-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
}

.form-row {
  display: flex;
  justify-content: flex-start;
  padding: 10px 10px;
}
.form-row .form-row-item {
  flex: 1;
  margin-left: 10px;
}

.section-heading-wrapper {
  margin-bottom: 40px;
  text-align: center;
}

.contact-info-wrapper {
  padding: 15px 20px;
  color: white;
}
.top-header .top-header-title {
  font-size: 20px;
  font-weight: 600;
}

.top-header .top-header-subtitle {
  font-size: 13px;
  font-weight: 300;
  margin-top: 4px;
  line-height: 170%;
}
.top-header-subtitle a {
  color: white;
  /* A43559 */
  font-weight: 300;
  font-size: 15px;
  padding-right: 2px;
  padding-left: 2px;
}
.social-media-box {
  margin-top: 20px;
}
.social-media-box .social-media-item div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
}
.social-media-box .social-media-item div a {
  margin-left: 10px;
  color: white;
}
.contact-info {
  margin-top: 50px;
}
.contact-info-row {
  margin-top: 10px;
  display: flex;
}
.contact-info-row > .contact-info-value {
  margin-left: 30px;
}

@media only screen and (max-width: 700px) {
  .contact-box-wrapper {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .contact-info {
    margin-top: 10px;
  }
  .contact-grid-container {
    box-shadow: none;
    /* border: 1px solid rgb(208 185 185 / 27%); */
    border-radius: 10px;
  }
  .form-row {
    padding: 10px 0px;
  }
}
