* {
  box-sizing: border-box;
}
body {
  font-size: 14px;
}
.v143_349-new {
  width: 100%;
}
.v143_350-new {
  width: 100%;
}
.v143_355-new {
  z-index: 999;
  background: rgba(28, 156, 224, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.v143_357-new {
  font-size: 18px;
  color: white;
  font-weight: 700;
  font-family: 'Lexend';
}

.v143_358-new {
  text-align: center;
  color: white;
  font-family: 'Lexend Deca';
  margin-top: 10px;
}

.v143_359-new {
  margin-top: 10px;
  text-align: center;
  background: white;
  padding: 16px 24px;
  opacity: 1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}
.v143_360-new {
  color: black;
  font-family: Lexend;
  font-weight: 500;
  font-size: 14px;
  opacity: 1;
  text-align: center;
}
