.frame-3-custom.group-39 {
  width: 1410px;
  margin-top: 40px;
}

.frame-3-custom .overlap-13 {
  background-color: #59a7f3;
  border-radius: 24px;
  /* height: 1742px; */
  position: relative;
  width: 1408px;
  padding-top: 160px;
  padding-left: 56px;
  overflow: hidden;
  padding-bottom: 40px;
}

.frame-3-custom .group-40 {
  height: 457px;
  left: 941px;
  position: absolute;
  top: 176px;
  width: 467px;
}

.frame-3-custom .vector-7 {
  height: 1706px;
  left: 0;
  position: absolute;
  top: 36px;
  width: 952px;
}

.frame-3-custom .vector-8 {
  height: 419px;
  left: 0;
  position: absolute;
  top: 36px;
  width: 952px;
}

.frame-3-custom .text-wrapper-20 {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 80px;
  font-weight: 700;
  letter-spacing: -2.4px;
  line-height: 92px;
  max-width: 772px;
}

.frame-3-custom .frame-7 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 8px;
  left: 64px;
  /* position: absolute; */
  top: 420px;
  width: 50vw;
  margin-top: 10px;
}

.frame-3-custom .frame-8 {
  align-items: flex-start;
  border: 2px solid;
  border-color: #4d8fcd;
  border-radius: 16px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  position: relative;
}

.frame-3-custom .group-41 {
  height: 40px;
  margin-right: -2px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.frame-3-custom .rectangle-22 {
  background-color: #4d8fcd;
  border-radius: 12px;
  height: 40px;
  width: 40px;
}

.frame-3-custom .text-wrapper-21 {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 600;
  /* height: 30px; */
  letter-spacing: -0.72px;
  line-height: normal;
}

.frame-3-custom .group-47 {
  /* height: 32px; */
  /* position: absolute; */
  /* width: 164px; */
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
}

.frame-3-custom .use-cases {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.72px;
  line-height: normal;
  /* position: absolute; */
  white-space: nowrap;
  /* width: 122px; */
}

.frame-3-custom .icon-instance-node-5 {
  height: 32px !important;
  left: 0 !important;
  /* position: absolute !important; */
  top: 0 !important;
  width: 32px !important;
}

.frame-3-custom .group-48 {
  /* height: 33px; */
  display: flex;
  /* align-items: center; */
  gap: 10px;
  /* margin-top: 110px; */
}

.frame-3-custom .text-wrapper-22 {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.72px;
  line-height: normal;
}

/* .frame-3-custom .group-48, */
.frame-3-custom .frame-9 {
  margin-left: 7vw;
  margin-top: 140px;
}
.frame-3-custom .frame-9 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
}

.frame-3-custom .group-49 {
  position: relative;
}

.frame-3-custom .frame-10 {
  align-items: center;
  display: flex;
  gap: 40px;
  position: relative;
}

.frame-3-custom .rectangle-23 {
  background-color: #4d8fcd;
  border-radius: 16px;
  height: 120px;
  position: relative;
  width: 120px;
}

.frame-3-custom .text-wrapper-23 {
  color: #263c49;
  font-family: 'Overpass', Helvetica;
  font-size: 72px;
  font-weight: 700;
  letter-spacing: -2.16px;
  line-height: normal;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .frame-3-custom.group-39 {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .frame-3-custom .overlap-13 {
    width: 93%;
    padding-top: 95px;
    padding-left: 16px;
    padding-bottom: 40px;
  }

  .frame-3-custom .text-wrapper-20 {
    font-size: 40px;
    line-height: 48px;
    max-width: 94%;
  }
  .frame-3-custom .frame-7 {
    gap: 5px;
    width: 100%;
    margin-top: 10px;
  }
  .frame-3-custom .frame-8 {
    /* display: inline-flex; */
    gap: 8px;
    padding: 4px 12px;
  }
  .frame-3-custom .text-wrapper-21 {
    font-size: 18px;
  }
  .frame-3-custom .frame-10 {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
  .frame-3-custom .text-wrapper-23 {
    font-size: 32px;
    line-height: normal;
  }
  .frame-3-custom .rectangle-23 {
    height: 60px;
    width: 60px;
  }
  .frame-3-custom .vector-7 {
    height: 1710px;
    left: 0;
    position: absolute;
    top: 36px;
    width: 96%;
  }

  .frame-3-custom .vector-8 {
    /* height: 419px; */
    left: 0;
    position: absolute;
    top: -87px;
    width: 96%;
  }
  .frame-3-custom .frame-9 {
    margin-left: 7vw;
    margin-top: 59px;
    gap: 10px;
  }
}
