.v75_86 {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  margin-bottom: 40px;
}
.v75_87 {
  text-align: center;
}
.v75_88 {
  /* color: rgba(0, 0, 0, 0.75);
  font-family: Lexend;
  font-weight: 300;
  font-size: 20px;
  margin-top: 12px; */
  text-align: center;
}

/* parts css */

.v9_376 {
  margin-left: 33px;
}
.v8_323 {
  color: rgba(0, 0, 0, 1);
  font-family: Lexend;
  font-weight: SemiBold;
  font-size: 26px;
  opacity: 1;
  text-align: left;
}
.v9_375 {
  margin: 35px 0px;
  opacity: 1;
}
.v9_370 {
  margin: 33px 0px;
  opacity: 1;
}
.boxWrapper {
  display: flex;
  align-items: center;
}
.v9_369 {
  opacity: 1;
}
.v9_369 img {
  width: 40px;
  height: 40px;
}
.v8_365 {
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  opacity: 1;
  text-align: left;
  font-weight: 600;
  margin-left: 20px;
  line-height: 28px;
}
.v8_365 span {
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  /* line-height: 18px; */
  margin-top: 32px;
}
.v9_373 {
  margin: 33px;
  opacity: 1;
}
.v9_371 {
  opacity: 1;
}
.v9_366 {
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v9_374 {
  margin: 33px;
  opacity: 1;
}
.v9_372 {
  opacity: 1;
}
.v9_367 {
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  opacity: 1;
  text-align: left;
}

.use-case-img {
  width: 100%;
  /* height: -webkit-fill-available; */
  display: flex;
  align-self: center;
  border-radius: 5px;
  height: 90%;
}

.divider-style {
  margin-top: 130px;
}
.use-case-grid-container {
  padding: 16px 10px;
}
.grid-part-box-wrapper {
  margin-top: 50px;
}
@media only screen and (max-width: 700px) {
  .v75_86 {
    margin-bottom: 4px;
  }
  .use-case-grid-container {
    padding: 0px;
    margin-top: 15px;
  }
  .v9_376 {
    margin-left: 3px;
    margin-top: 10px;
  }
  .v9_375 {
    margin: 5px;
  }
  .v9_370 {
    margin: 15px;
  }
  .grid-part-box-wrapper {
    margin-top: 0px;
  }
}
