.v19_92 {
  background: rgba(255, 255, 255, 1);
  /* padding: 32px 140px; */
  /* margin: 739px; */
  opacity: 1;
}
.v19_95 {
  margin-right: 28px;
  opacity: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.v19_95 span,
.v19_95 div {
  margin: 20px;
}
.v19_95 a {
  text-decoration: none;
}
.v19_96 {
  color: rgba(32, 33, 36, 1);
  font-family: Lexend;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
  cursor: pointer;
}
.v19_97 {
  color: rgba(32, 33, 36, 1);
  font-family: Lexend;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v162_864 {
  color: rgba(32, 33, 36, 1);
  font-family: Lexend;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v19_98 {
  color: rgba(32, 33, 36, 1);
  font-family: Lexend;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v19_99 {
  background: rgba(0, 167, 255, 1);
  padding: 16px 24px;
  opacity: 1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}
.v19_100 {
  color: rgba(255, 255, 255, 1);
  font-family: Lexend;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
