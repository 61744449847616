.v19_102 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.v19_103 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.v19_104_1 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  /* line-height: 120%; */

  color: #202124;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.v19_104 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;

  color: #202124;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.v19_105 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;

  color: #6b6b6b;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.v19_106 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;

  background: #00a7ff;
  border-radius: 5px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.v19_107 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.v19_108 {
  flex: none;
  order: 1;
  flex-grow: 0;
}
