.App {
  text-align: center;
}
body {
  scroll-behavior: smooth;
}

.App-header {
  background-color: white;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  margin-bottom: 60px;
}

.App-link {
  color: #61dafb;
}
.page-wrapper {
  padding-top: 0px;
}
.section-grid-wrapper {
  padding: 6px 140px;
  margin-top: 20px;
}
.section-grid-wrapper-one {
  padding: 6px 140px;
  margin-top: 0px;
}
.sub-sections {
  margin-top: 40px;
}
.section-heading-title {
  color: rgb(39 39 39 / 61%);
  font-family: Lexend;
  font-weight: 600;
  font-size: 26px;
  text-transform: uppercase;
}
.section-heading-subtitle {
  color: rgba(0, 0, 0, 1);
  font-family: Lexend;
  font-weight: 300;
  font-size: 30px;
  margin-top: 13px;
  line-height: 130%;
}
.page-top-heading {
  font-size: 40px;
}
@media only screen and (max-width: 700px) {
  .page-wrapper {
    padding-top: 0px;
  }
  .section-grid-wrapper {
    padding: 15px 16px;
    margin-top: 10px;
  }
  .section-grid-wrapper-one {
    padding: 15px 16px;
    margin-top: 10px;
  }
  .sub-sections {
    margin-top: 40px;
  }
  .page-top-heading {
    font-size: 26px;
  }

  .section-heading-title {
    font-size: 20px;
  }
  .section-heading-subtitle {
    font-size: 18px;
    text-align: center;
  }

  #top-arrow.show {
    display: none;
  }
}

.v19_106,
.v143_359,
.v143_359-new,
.v19_99,
.v138_235 {
  cursor: pointer;
}

#top-arrow {
  position: absolute;
  right: 60px;
  bottom: 60px;
  background-color: aliceblue;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 999;
}

#top-arrow {
  display: none;
}

#top-arrow.show {
  display: block;
}
