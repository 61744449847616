.v160_400 {
  margin: 12px;
  opacity: 1;
  text-align: center;
}

/* left grid */
.v75_95 {
  /* margin: 36px; */
  opacity: 1;
  padding: 16px 20px;
}
.v75_165 {
  /* margin: 48px; */
  opacity: 1;
}
.v75_166 {
  margin: 24px;
  opacity: 1;
}
.v75_167 {
  color: rgba(0, 0, 0, 1);
  font-family: Lexend;
  font-weight: SemiBold;
  font-size: 28px;
  opacity: 1;
  text-align: left;
}
.v75_168 {
  color: rgba(0, 0, 0, 1);
  font-family: Lexend;
  font-weight: 100;
  font-size: 16px;
  opacity: 1;
  text-align: left;
  margin-top: 30px;
  line-height: 29px;
}
.v75_169 {
  padding: 16px 24px;
  opacity: 1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.v75_170 {
  color: rgba(255, 255, 255, 1);
  font-family: 'Lexend';
  font-weight: 600;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}

/* Carousel Styling */

.v75_161 {
  opacity: 1;
}
.v75_124 {
  color: rgba(32, 33, 36, 1);
  font-family: 'Lexend';
  font-weight: 600;
  font-size: 20px;
  opacity: 1;
  text-align: left;
  line-height: 29px;
}
.v75_125 {
  margin-top: 16px;
  font-size: 16px;
  opacity: 1;
  text-align: left;
  line-height: 29px;
}
.v75_125 span {
  font-weight: 600;
}
.v75_151 {
  color: rgba(32, 33, 36, 1);
  font-family: 'Lexend';
  font-weight: 600;
  font-size: 20px;
  opacity: 1;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}
.v75_120 {
  opacity: 1;
}
.v75_121 {
  opacity: 1;
}
.v75_160 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.v75_159 {
  color: rgba(255, 255, 255, 1);
  font-family: 'Lexend Deca';
  font-weight: 700;
  font-size: 12px;
  opacity: 1;
  padding: 8px 24px;
  align-items: center;
  border-radius: 6px;
}
.v75_152 {
  font-size: 14px;
  opacity: 1;
  text-align: left;
  line-height: 25px;
  font-weight: 600;
  font-family: 'Lexend Deca';
}
.v75_159.green {
  background-color: #14ad2c;
}
.v75_159.red {
  background-color: #ec2121;
}
