/* .v133_544 {
  width: 100%;
  height: 1999px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 80px 140px;
  margin: 56px;
  opacity: 1;
  position: absolute;
  top: 712px;
  left: 1px;
  overflow: hidden;
}
.v133_542 {
  width: 100%;
  height: 537px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 24px;
  opacity: 1;
  position: absolute;
  top: 196px;
  left: 140px;
  overflow: hidden;
}
.v133_507 {
  width: 371px;
  height: 537px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_482 {
  width: 371px;
  height: 537px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.10000000149011612);
  overflow: hidden;
}
.v133_487 {
  width: 371px;
  height: 287px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.v133_494 {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 312px;
  overflow: hidden;
}
.v133_495 {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.6000000238418579);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
}
.v133_496 {
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 6px;
  left: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v133_497 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 11px;
  border-radius: 50%;
}
.v133_498 {
  width: 7px;
  height: 13px;
  opacity: 1;
  position: absolute;
  top: 5px;
  left: 6px;
  border: 2px solid rgba(32, 33, 36, 1);
}
.v133_499 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 15px;
  left: 11px;
  border-radius: 50%;
}
.v133_500 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 8px;
  left: 0px;
  border-radius: 50%;
}
.v133_506 {
  width: 323px;
  height: 202px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 24px;
  opacity: 1;
  position: absolute;
  top: 311px;
  left: 24px;
  overflow: hidden;
}
.v133_505 {
  width: 323px;
  height: 158px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 12px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_504 {
  width: 163px;
  height: 59px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 6px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_503 {
  width: 73px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v133_490 {
  width: 163px;
  color: rgba(79, 79, 79, 1);
  position: absolute;
  top: 24px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Medium;
  font-size: 28px;
  opacity: 1;
  text-align: left;
}
.v133_493 {
  width: 323px;
  color: rgba(107, 107, 107, 1);
  position: absolute;
  top: 71px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Light;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v133_501 {
  width: 104px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 182px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v133_508 {
  width: 371px;
  height: 537px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 395px;
  overflow: hidden;
}
.v133_509 {
  width: 371px;
  height: 537px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.10000000149011612);
  overflow: hidden;
}
.v133_510 {
  width: 371px;
  height: 287px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v133_511 {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 312px;
  overflow: hidden;
}
.v133_512 {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.6000000238418579);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
}
.v133_513 {
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 6px;
  left: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v133_514 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 11px;
  border-radius: 50%;
}
.v133_515 {
  width: 7px;
  height: 13px;
  opacity: 1;
  position: absolute;
  top: 5px;
  left: 6px;
  border: 2px solid rgba(32, 33, 36, 1);
}
.v133_516 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 15px;
  left: 11px;
  border-radius: 50%;
}
.v133_517 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 8px;
  left: 0px;
  border-radius: 50%;
} */

.v133_543 {
  color: rgba(32, 33, 36, 1);
  font-family: Lexend Deca;
  font-weight: Bold;
  font-size: 48px;
  opacity: 1;
  text-align: left;
  margin-bottom: 10px;
}
.v133_518 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 24px;
  opacity: 1;
  overflow: hidden;
}
.v133_519 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: 12px;
  opacity: 1;
}
.v133_520 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: 6px;
  opacity: 1;
}
.v133_521 {
  color: rgba(53, 54, 58, 1);
  font-family: Lexend Deca;
  font-weight: 400;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v133_522 {
  color: rgba(79, 79, 79, 1);
  font-family: Lexend Deca;
  font-weight: 600;
  font-size: 28px;
  opacity: 1;
  text-align: left;
  margin-top: 8px;
}
.v133_523 {
  color: rgba(107, 107, 107, 1);

  font-family: Lexend Deca;
  font-weight: 400;
  font-size: 16px;
  opacity: 1;
  text-align: left;
  margin-top: 12px;
}
.v133_524 {
  color: rgba(53, 54, 58, 1);

  font-family: Lexend Deca;
  font-weight: 400;
  font-size: 16px;
  opacity: 1;
  text-align: left;
  margin-top: 6px;
}
/* 
.v133_525 {
  width: 371px;
  height: 537px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 790px;
  overflow: hidden;
}
.v133_526 {
  width: 371px;
  height: 537px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.10000000149011612);
  overflow: hidden;
}
.v133_527 {
  width: 371px;
  height: 287px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v133_528 {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 312px;
  overflow: hidden;
}
.v133_529 {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.6000000238418579);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
}
.v133_530 {
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 6px;
  left: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v133_531 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 11px;
  border-radius: 50%;
}
.v133_532 {
  width: 7px;
  height: 13px;
  opacity: 1;
  position: absolute;
  top: 5px;
  left: 6px;
  border: 2px solid rgba(32, 33, 36, 1);
}
.v133_533 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 15px;
  left: 11px;
  border-radius: 50%;
}
.v133_534 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 8px;
  left: 0px;
  border-radius: 50%;
}
.v133_535 {
  width: 323px;
  height: 202px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 24px;
  opacity: 1;
  position: absolute;
  top: 311px;
  left: 24px;
  overflow: hidden;
}
.v133_536 {
  width: 323px;
  height: 158px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 12px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_537 {
  width: 167px;
  height: 59px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 6px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_538 {
  width: 73px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v133_539 {
  width: 167px;
  color: rgba(79, 79, 79, 1);
  position: absolute;
  top: 24px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Medium;
  font-size: 28px;
  opacity: 1;
  text-align: left;
}
.v133_540 {
  width: 323px;
  color: rgba(107, 107, 107, 1);
  position: absolute;
  top: 71px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Light;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v133_541 {
  width: 104px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 182px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v133_545 {
  width: 100%;
  height: 537px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 24px;
  opacity: 1;
  position: absolute;
  top: 789px;
  left: 140px;
  overflow: hidden;
}
.v133_546 {
  width: 371px;
  height: 537px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_547 {
  width: 371px;
  height: 537px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.10000000149011612);
  overflow: hidden;
}
.v133_548 {
  width: 371px;
  height: 287px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v133_549 {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 312px;
  overflow: hidden;
}
.v133_550 {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.6000000238418579);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
}
.v133_551 {
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 6px;
  left: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v133_552 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 11px;
  border-radius: 50%;
}
.v133_553 {
  width: 7px;
  height: 13px;
  opacity: 1;
  position: absolute;
  top: 5px;
  left: 6px;
  border: 2px solid rgba(32, 33, 36, 1);
}
.v133_554 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 15px;
  left: 11px;
  border-radius: 50%;
}
.v133_555 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 8px;
  left: 0px;
  border-radius: 50%;
}
.v133_556 {
  width: 323px;
  height: 202px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 24px;
  opacity: 1;
  position: absolute;
  top: 311px;
  left: 24px;
  overflow: hidden;
}
.v133_557 {
  width: 323px;
  height: 158px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 12px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_558 {
  width: 142px;
  height: 59px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 6px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_559 {
  width: 73px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v133_560 {
  width: 142px;
  color: rgba(79, 79, 79, 1);
  position: absolute;
  top: 24px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Medium;
  font-size: 28px;
  opacity: 1;
  text-align: left;
}
.v133_561 {
  width: 323px;
  color: rgba(107, 107, 107, 1);
  position: absolute;
  top: 71px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Light;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v133_562 {
  width: 104px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 182px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v133_563 {
  width: 371px;
  height: 537px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 395px;
  overflow: hidden;
}
.v133_564 {
  width: 371px;
  height: 537px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.10000000149011612);
  overflow: hidden;
}
.v133_565 {
  width: 371px;
  height: 287px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v133_566 {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 312px;
  overflow: hidden;
}
.v133_567 {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.6000000238418579);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
}
.v133_568 {
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 6px;
  left: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v133_569 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 11px;
  border-radius: 50%;
}
.v133_570 {
  width: 7px;
  height: 13px;
  opacity: 1;
  position: absolute;
  top: 5px;
  left: 6px;
  border: 2px solid rgba(32, 33, 36, 1);
}
.v133_571 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 15px;
  left: 11px;
  border-radius: 50%;
}
.v133_572 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 8px;
  left: 0px;
  border-radius: 50%;
}
.v133_573 {
  width: 323px;
  height: 202px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 24px;
  opacity: 1;
  position: absolute;
  top: 311px;
  left: 24px;
  overflow: hidden;
}
.v133_574 {
  width: 323px;
  height: 158px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 12px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_575 {
  width: 112px;
  height: 59px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 6px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_576 {
  width: 73px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v133_577 {
  width: 112px;
  color: rgba(79, 79, 79, 1);
  position: absolute;
  top: 24px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Medium;
  font-size: 28px;
  opacity: 1;
  text-align: left;
}
.v133_578 {
  width: 323px;
  color: rgba(107, 107, 107, 1);
  position: absolute;
  top: 71px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Light;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v133_579 {
  width: 104px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 182px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v133_580 {
  width: 371px;
  height: 537px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 790px;
  overflow: hidden;
}
.v133_581 {
  width: 371px;
  height: 537px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.10000000149011612);
  overflow: hidden;
}
.v133_582 {
  width: 371px;
  height: 287px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v133_583 {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 312px;
  overflow: hidden;
}
.v133_584 {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.6000000238418579);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
}
.v133_585 {
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 6px;
  left: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v133_586 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 11px;
  border-radius: 50%;
}
.v133_587 {
  width: 7px;
  height: 13px;
  opacity: 1;
  position: absolute;
  top: 5px;
  left: 6px;
  border: 2px solid rgba(32, 33, 36, 1);
}
.v133_588 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 15px;
  left: 11px;
  border-radius: 50%;
}
.v133_589 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 8px;
  left: 0px;
  border-radius: 50%;
}
.v133_590 {
  width: 323px;
  height: 202px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 24px;
  opacity: 1;
  position: absolute;
  top: 311px;
  left: 24px;
  overflow: hidden;
}
.v133_591 {
  width: 323px;
  height: 158px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 12px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_592 {
  width: 103px;
  height: 59px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 6px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v133_593 {
  width: 73px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v133_594 {
  width: 103px;
  color: rgba(79, 79, 79, 1);
  position: absolute;
  top: 24px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Medium;
  font-size: 28px;
  opacity: 1;
  text-align: left;
}
.v133_595 {
  width: 323px;
  color: rgba(107, 107, 107, 1);
  position: absolute;
  top: 71px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Light;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v133_596 {
  width: 104px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 182px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v134_606 {
  width: 100%;
  height: 537px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 24px;
  opacity: 1;
  position: absolute;
  top: 1382px;
  left: 140px;
  overflow: hidden;
}
.v134_607 {
  width: 371px;
  height: 537px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v134_608 {
  width: 371px;
  height: 537px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.10000000149011612);
  overflow: hidden;
}
.v134_609 {
  width: 371px;
  height: 287px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v134_610 {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 312px;
  overflow: hidden;
}
.v134_611 {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.6000000238418579);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
}
.v134_612 {
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 6px;
  left: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v134_613 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 11px;
  border-radius: 50%;
}
.v134_614 {
  width: 7px;
  height: 13px;
  opacity: 1;
  position: absolute;
  top: 5px;
  left: 6px;
  border: 2px solid rgba(32, 33, 36, 1);
}
.v134_615 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 15px;
  left: 11px;
  border-radius: 50%;
}
.v134_616 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 8px;
  left: 0px;
  border-radius: 50%;
}
.v134_617 {
  width: 323px;
  height: 202px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 24px;
  opacity: 1;
  position: absolute;
  top: 311px;
  left: 24px;
  overflow: hidden;
}
.v134_618 {
  width: 323px;
  height: 158px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 12px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v134_619 {
  width: 142px;
  height: 59px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 6px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v134_620 {
  width: 73px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v134_621 {
  width: 142px;
  color: rgba(79, 79, 79, 1);
  position: absolute;
  top: 24px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Medium;
  font-size: 28px;
  opacity: 1;
  text-align: left;
}
.v134_622 {
  width: 323px;
  color: rgba(107, 107, 107, 1);
  position: absolute;
  top: 71px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Light;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v134_623 {
  width: 104px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 182px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v134_624 {
  width: 371px;
  height: 537px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 395px;
  overflow: hidden;
}
.v134_625 {
  width: 371px;
  height: 537px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.10000000149011612);
  overflow: hidden;
}
.v134_626 {
  width: 371px;
  height: 287px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v134_627 {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 312px;
  overflow: hidden;
}
.v134_628 {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.6000000238418579);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
}
.v134_629 {
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 6px;
  left: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v134_630 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 11px;
  border-radius: 50%;
}
.v134_631 {
  width: 7px;
  height: 13px;
  opacity: 1;
  position: absolute;
  top: 5px;
  left: 6px;
  border: 2px solid rgba(32, 33, 36, 1);
}
.v134_632 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 15px;
  left: 11px;
  border-radius: 50%;
}
.v134_633 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 8px;
  left: 0px;
  border-radius: 50%;
}
.v134_634 {
  width: 323px;
  height: 202px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 24px;
  opacity: 1;
  position: absolute;
  top: 311px;
  left: 24px;
  overflow: hidden;
}
.v134_635 {
  width: 323px;
  height: 158px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 12px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v134_636 {
  width: 112px;
  height: 59px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 6px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v134_637 {
  width: 73px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v134_638 {
  width: 112px;
  color: rgba(79, 79, 79, 1);
  position: absolute;
  top: 24px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Medium;
  font-size: 28px;
  opacity: 1;
  text-align: left;
}
.v134_639 {
  width: 323px;
  color: rgba(107, 107, 107, 1);
  position: absolute;
  top: 71px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Light;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v134_640 {
  width: 104px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 182px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v134_641 {
  width: 371px;
  height: 537px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 790px;
  overflow: hidden;
}
.v134_642 {
  width: 371px;
  height: 537px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.10000000149011612);
  overflow: hidden;
}
.v134_643 {
  width: 371px;
  height: 287px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v134_644 {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 312px;
  overflow: hidden;
}
.v134_645 {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.6000000238418579);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
}
.v134_646 {
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 6px;
  left: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.v134_647 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 11px;
  border-radius: 50%;
}
.v134_648 {
  width: 7px;
  height: 13px;
  opacity: 1;
  position: absolute;
  top: 5px;
  left: 6px;
  border: 2px solid rgba(32, 33, 36, 1);
}
.v134_649 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 15px;
  left: 11px;
  border-radius: 50%;
}
.v134_650 {
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  top: 8px;
  left: 0px;
  border-radius: 50%;
}
.v134_651 {
  width: 323px;
  height: 202px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 24px;
  opacity: 1;
  position: absolute;
  top: 311px;
  left: 24px;
  overflow: hidden;
}
.v134_652 {
  width: 323px;
  height: 158px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 12px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v134_653 {
  width: 103px;
  height: 59px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 6px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v134_654 {
  width: 73px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}
.v134_655 {
  width: 103px;
  color: rgba(79, 79, 79, 1);
  position: absolute;
  top: 24px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Medium;
  font-size: 28px;
  opacity: 1;
  text-align: left;
}
.v134_656 {
  width: 323px;
  color: rgba(107, 107, 107, 1);
  position: absolute;
  top: 71px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Light;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v134_657 {
  width: 104px;
  color: rgba(53, 54, 58, 1);
  position: absolute;
  top: 182px;
  left: 0px;
  font-family: Lexend Deca;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v143_361 {
  width: 100%;
  height: 369px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 2711px;
  left: 2px;
  overflow: hidden;
}
.v143_362 {
  width: 100%;
  height: 369px;
  background: rgba(28, 156, 224, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v143_363 {
  width: 335px;
  height: 335px;
  background: rgba(0, 167, 255, 1);
  opacity: 1;
  position: absolute;
  top: 125px;
  left: 76px;
  border-radius: 50%;
}
.v143_364 {
  width: 178px;
  height: 178px;
  background: rgba(0, 167, 255, 1);
  opacity: 1;
  position: absolute;
  top: 22px;
  left: 38px;
  border-radius: 50%;
}
.v143_365 {
  width: 197px;
  height: 520px;
  background: rgba(0, 167, 255, 1);
  opacity: 1;
  position: absolute;
  top: 146px;
  left: 1040px;
  border-top-left-radius: 231px;
  border-top-right-radius: 231px;
  border-bottom-left-radius: 231px;
  border-bottom-right-radius: 231px;
  transform: rotate(-42deg);
  overflow: hidden;
}
.v143_366 {
  width: 197px;
  height: 520px;
  background: rgba(0, 167, 255, 1);
  opacity: 1;
  position: absolute;
  top: 212px;
  left: 1053px;
  border-top-left-radius: 231px;
  border-top-right-radius: 231px;
  border-bottom-left-radius: 231px;
  border-bottom-right-radius: 231px;
  transform: rotate(-42deg);
  overflow: hidden;
}
.v143_367 {
  width: 641px;
  height: 257px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 64px;
  opacity: 1;
  position: absolute;
  top: 56px;
  left: 399px;
  overflow: hidden;
}
.v143_368 {
  width: 641px;
  height: 141px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 33px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v143_369 {
  width: 641px;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Lexend;
  font-weight: SemiBold;
  font-size: 36px;
  opacity: 1;
  text-align: center;
}
.v143_370 {
  width: 371px;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 98px;
  left: 135px;
  font-family: Lexend;
  font-weight: Light;
  font-size: 24px;
  opacity: 1;
  text-align: left;
}
.v143_371 {
  width: 165px;
  height: 52px;
  background: rgba(255, 255, 255, 1);
  padding: 16px 24px;
  opacity: 1;
  position: absolute;
  top: 205px;
  left: 238px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}
.v143_372 {
  width: 117px;
  color: rgba(16, 5, 77, 1);
  position: absolute;
  top: 16px;
  left: 24px;
  font-family: Lexend;
  font-weight: SemiBold;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.name {
  color: #fff;
} */
