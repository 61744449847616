.about-new-page-wrapper {
  background-color: #0e231c;
  position: relative;
  width: 100vw;
  height: max-content;
  min-height: 100vh;
}
.width-controller {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.about-new-page .group {
  /* height: 40px; */
  width: 483px;
}

.about-new-page .overlap-group-wrapper {
  height: 40px;
  left: 297px;
  /* position: absolute; */
  top: 0;
  width: 186px;
}

.about-new-page .overlap-group {
  border-radius: 24px;
  height: 40px;
  position: relative;
}

.about-new-page .rectangle {
  background-color: #75ce4c;
  border-radius: 24px;
  height: 40px;
  left: 0;
  /* position: absolute; */
  top: 0;
  transform: rotate(180deg);
  width: 186px;
}

.about-new-page .div {
  height: 25px;
  left: 16px;
  /* position: absolute; */
  top: 8px;
  width: 156px;
}

.about-new-page .text-wrapper {
  color: #0e231c;
  font-family: 'Overpass', Helvetica;
  font-size: 18px;
  font-weight: 600;
  height: 23px;
  left: 0;
  letter-spacing: -0.9px;
  line-height: normal;
  /* position: absolute; */
  top: 2px;
}

.about-new-page .arrow-arrow-right-MD {
  height: 24px !important;
  left: 130px !important;
  position: absolute !important;
  top: 0 !important;
  width: 24px !important;
}

.about-new-page .frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 24px;
  left: 0;
  /* position: absolute; */
  top: 10px;
}

.about-new-page .text-wrapper-2 {
  color: #fdfcfc;
  font-family: 'Overpass', Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.9px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

/* 
















*/

.about-new-page .overlap {
  background-color: #fdfcfc;
  border-radius: 24px;
  max-width: 1408px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}
.about-new-page-wrapper .overall-wrapper {
  display: flex;
  justify-content: center;
  /* margin-top: 40px; */
}

.about-new-page .overlap-2 {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-new-page .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 80%;
}

.about-new-page .text {
  align-self: stretch;
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.72px;
  line-height: normal;
  position: relative;
  /* text-align: center; */
  text-align: left;
}

.about-new-page .span {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.72px;
}

.about-new-page .rectangle-2 {
  border: 1.5px solid;
  border-color: #e0e0e0;
  border-radius: 24px;
  height: 414px;
  left: 0;
  /* position: absolute; */
  top: 0;
  width: 1360px;
}

.about-new-page .group-2 {
  display: flex;
  justify-content: center;
  gap: 14px;
  align-items: center;
}

.about-new-page .mission {
  color: #263d49;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 40px;
  letter-spacing: -0.72px;
  line-height: normal;
  /* position: absolute; */
  top: 4px;
}

.about-new-page .text-wrapper-3 {
  color: #263c49;
  font-family: 'Overpass', Helvetica;
  font-size: 72px;
  font-weight: 700;
  letter-spacing: -2.16px;
  line-height: 81px;
  text-align: center;
  white-space: nowrap;
  margin-top: 70px;
}
.custom-box-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-top: 25px;
  border: 1.5px solid;
  border-color: #e0e0e0;
  width: 96%;
  border-radius: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.about-new-page .group-3 {
  background-color: #0e231c;
  border-radius: 24px;
  width: 98%;
  padding-bottom: 40px;
}
.team-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.person-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
}
.person-box {
  display: flex;
  align-items: center;
  gap: 20px;
}

.about-new-page .group-4 {
  height: 140px;
  left: 509px;
  /* position: absolute; */
  top: 114px;
  width: 376px;
}

.about-new-page .group-5 {
  height: 98px;
  left: 164px;
  /* position: absolute; */
  top: 21px;
  width: 216px;
}

.about-new-page .co-founder-chief {
  color: #fdfcfc;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.72px;
  line-height: normal;
  opacity: 0.8;
  /* position: absolute; */
  top: 38px;
  width: 212px;
}

.about-new-page .text-wrapper-4 {
  color: #fdfcfc;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 0;
  letter-spacing: -0.72px;
  line-height: normal;
  /* position: absolute; */
  top: 0;
}

.about-new-page .icon-linkedin {
  height: 24px !important;
  left: 153px !important;
  /* position: absolute !important; */
  top: 1px !important;
  width: 24px !important;
  margin-left: 12px;
}

.about-new-page .img {
  height: 140px;
  left: 0;
  object-fit: cover;
  /* position: absolute; */
  top: 0;
  width: 140px;
}

.about-new-page .group-wrapper {
  /* height: 98px;
  width: 233px; */
}

.about-new-page .div-wrapper {
  height: 98px;
}

.about-new-page .group-7 {
  height: 98px;
  position: relative;
  width: 237px;
}

.about-new-page .co-founder-CEO {
  color: #fdfcfc;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.72px;
  line-height: normal;
  opacity: 0.8;
}

.about-new-page .overlap-group-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.about-new-page .icon-linkedin-5 {
  height: 24px !important;
  left: 119px !important;
  /* position: absolute !important; */
  top: 30px !important;
  width: 24px !important;
}

.about-new-page .text-wrapper-5 {
  color: #fdfcfc;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.72px;
  line-height: normal;
  /* width: 233px; */
}

.about-new-page .img-2 {
  height: 140px;
  width: 140px;
}
.about-new-page .img-2,
.about-new-page .person-img {
  height: 140px;
  width: 140px;
}

.about-new-page .group-8 {
  height: 140px;
  left: 941px;
  /* position: absolute; */
  top: 114px;
  width: 363px;
}

.about-new-page .group-9 {
  height: 128px;
  left: 164px;
  /* position: absolute; */
  top: 6px;
  width: 203px;
}

.about-new-page .founding-engineer {
  color: #fdfcfc;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.72px;
  line-height: normal;
  opacity: 0.8;
  /* position: absolute; */
  top: 68px;
  width: 199px;
}

.about-new-page .overlap-group-3 {
  height: 60px;
  left: 0;
  /* position: absolute; */
  top: 0;
  width: 177px;
}

.about-new-page .text-wrapper-6 {
  color: #fdfcfc;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 0;
  letter-spacing: -0.72px;
  line-height: normal;
  /* position: absolute; */
  top: 0;
  width: 177px;
}

.about-new-page .group-10 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 10px;
}

.about-new-page .text-wrapper-7 {
  color: #fdfcfc;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 40px;
  letter-spacing: -0.72px;
  line-height: normal;
  /* position: absolute; */
  top: 4px;
}

.about-new-page .group-11 {
  height: 34px;
  left: 604px;
  /* position: absolute; */
  top: 310px;
  width: 155px;
}

.about-new-page .navigation-building {
  height: 32px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 32px !important;
}

.about-new-page .group-12 {
}

.about-new-page .group-13 {
  height: 140px;
  left: 540px;
  /* position: absolute; */
  top: 0;
  width: 479px;
}

.about-new-page .group-14 {
  height: 68px;
  left: 164px;
  /* position: absolute; */
  top: 36px;
  width: 319px;
}

.about-new-page .text-wrapper-8 {
  color: #fdfcfc;
  font-family: 'Overpass', Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.72px;
  line-height: normal;
  opacity: 0.8;
  /* position: absolute; */
  top: 38px;
}

.about-new-page .icon-linkedin-instance {
  height: 24px !important;
  left: 146px !important;
  position: absolute !important;
  top: 1px !important;
  width: 24px !important;
}

.about-new-page .group-15 {
  height: 140px;
  left: 0;
  /* position: absolute; */
  top: 0;
  width: 468px;
}

.about-new-page .group-16 {
  height: 68px;
  left: 164px;
  /* position: absolute; */
  top: 36px;
  width: 308px;
}

.about-new-page .icon-linkedin-5-instance {
  height: 24px !important;
  left: 280px !important;
  position: absolute !important;
  top: 1px !important;
  width: 24px !important;
}

.about-new-page .frame-2 {
  height: 40px;
  left: 18px;
  /* position: absolute; */
  top: 24px;
  width: 152px;
}

@media only screen and (max-width: 600px) {
  .person-wrapper {
    flex-direction: column;
    gap: 0px;
  }
  .about-new-page .overlap {
    max-width: 96%;
  }
  .about-new-page .overall-wrapper {
    margin-top: 0px;
  }
  .about-new-page .text-wrapper-3 {
    font-size: 42px;
    margin-top: 30px;
  }
  .custom-box-wrapper {
    grid-gap: 25px;
    gap: 25px;
    margin-top: 0px;
    border: 0px;
    width: 96%;
    padding-top: 0px;
    padding-bottom: 5px;
  }
  .about-new-page .flexcontainer {
    width: 96%;
    gap: 10px;
  }
  .about-new-page .text {
    font-size: 16px;
    line-height: 130%;
  }
  .about-new-page .text .span {
    font-size: 16px;
  }
  .person-box {
    flex-direction: column;
    width: 85%;
  }
  .about-new-page .group-7 {
    width: inherit;
    height: initial;
  }
  .about-new-page .group-wrapper {
    text-align: center;
  }
  .about-new-page .overlap-group-2 {
    justify-content: center;
  }
  .about-new-page .text-wrapper-5 {
    font-size: 18px;
  }
  .about-new-page .icon-linkedin-5 {
    height: 18px !important;
    width: 18px !important;
  }
  .about-new-page .co-founder-CEO {
    font-size: 18px;
    margin-top: 7px;
  }
}
