.v160_400 {
  margin: 12px;
  opacity: 1;
  text-align: center;
}

.tabWrapper {
  padding: 40;
}
.tab {
  background-color: #fff;
  border-radius: 10px;
  padding: 18px 30px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}
.tab.bgGray {
  /* background-color: #f8f8f8; */
  background-color: #ebebeb;
}
.tab .tabTitle {
  font-size: 24px;
  font-family: 'Lexend';
  line-height: 43px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.tab .tabDescription {
  font-size: 16px;
  font-family: 'Lexend';
  line-height: 28px;
  font-weight: 300;
  padding-top: 10px;
  /* display: none; */
}
.tab .tabDescription.hideit {
  display: none;
}

.imgWrapper {
  padding-left: 90px;
  padding-top: 40px;
  height: auto;
  width: 100%;
}

.imgWrapper img {
  /* height: auto; */
  width: inherit;
}

.grid-separator {
  margin-top: 40px;
}
@media only screen and (max-width: 700px) {
  .imgWrapper {
    padding-left: 10px;
    padding-top: 20px;
  }
  .grid-separator {
    margin-top: 10px;
  }
}
